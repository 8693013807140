import { defineStore } from 'pinia';

export const useMyUiStore = defineStore({
  id: 'myUiStore',
  // state: () => ({ tagLine: 'Testing' }),
  state: () => ({
    isAuth: false,
    userId: '',
    name: '',
    email: '',
    displayImg: '',
    dailyCredits: null,
    credits: null,
    ip: '',
    tagLine: 'Turn YouTube Videos into Twitter Threads with AI',
  }),
  actions: {
    async getUserProfile() {
      try {
        const userInfo: {
          _id: string;
          name: string;
          email: string;
          tier: number;
          credits: any;
          thumbnail: any;
        } = await $fetch(`${useRuntimeConfig().public.apiUrl}/user/`, {
          method: 'GET',
          credentials: 'include',
        });

        this.userId = userInfo._id;
        this.name = userInfo.name;
        this.displayImg = userInfo?.thumbnail?.medium;
        this.email = userInfo.email;
        this.credits = userInfo.credits;
        this.isAuth = true;
      } catch (err) {
        console.error(err);
      }
    },
    async checkDailyQuota() {
      try {
        // get IP
        if (!this.ip) this.ip = await useMyServicesStore().getUserIp();

        // get results
        const result: {
          credits: any;
          dailyCredits: any;
        } = await $fetch(`${useRuntimeConfig().public.apiUrl}/user/daily-quota?ip=${this.ip}`, {
          method: 'GET',
          credentials: 'include',
        });
        this.credits = result?.credits;
        this.dailyCredits = result?.dailyCredits;
      } catch (err) {
        console.error(err);
      }
    },
    async signIn(args: any) {
      this.isAuth = false;
      try {
        const { email, name, password } = args;
        const response: {
          _id: string;
          name: string;
          email: string;
          credits: any;
        } = await $fetch(`${useRuntimeConfig().public.apiUrl}/user/login`, {
          method: 'POST',
          credentials: 'include',
          body: {
            name,
            email,
            password,
          },
        });

        this.userId = response._id;
        this.name = response.name;
        this.email = response.email;
        this.credits = response.credits;
        this.isAuth = true;

        // redirect to main page
        useRouter().push('/');
      } catch (err) {
        throw err;
      }
    },
    async signUp(args: any) {
      this.isAuth = false;
      try {
        const { email, name, password } = args;
        const response: {
          _id: string;
          name: string;
          email: string;
          credits: any;
        } = await $fetch(`${useRuntimeConfig().public.apiUrl}/user/register`, {
          method: 'POST',
          credentials: 'include',
          body: {
            name,
            email,
            password,
          },
        });

        this.userId = response._id;
        this.name = response.name;
        this.email = response.email;
        this.credits = response.credits;
        this.isAuth = true;

        // redirect to main page
        useRouter().push('/');
      } catch (err) {
        throw err;
      }
    },
    async logout() {
      try {
        this.isAuth = false;
        this.userId = '';
        this.name = '';
        this.displayImg = '';
        this.email = '';
        this.credits = null;
        this.dailyCredits = null;

        await $fetch(`${useRuntimeConfig().public.apiUrl}/user/logout`, {
          method: 'GET',
          credentials: 'include',
        });

        // redirect to login
        useRouter().push('/login');
      } catch (err) {
        throw err;
      }
    },
  },
  persist: [
    {
      pick: ['isAuth', 'userId', 'name', 'displayImg', 'email', 'credits'],
      storage: typeof window !== 'undefined' ? localStorage : null,
    },
    {
      pick: ['ip'],
      storage: typeof window !== 'undefined' ? sessionStorage : null,
    },
  ],
});
